import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid, Typography, Box, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import { LoadingData } from '../../ui/components/LoadingData';
import { docentesApi } from '../../api/docentesApi';
import Swal from 'sweetalert2';
import { formulariosApi } from '../../api/formulariosApi';
import { TipoRespuestaPreguntaDocente } from './TipoRespuestaPreguntaDocente';

export const DetalleEstudiantes = ({ listaEstudiantes = [], horario, esAdmin, numIdentificacionDocente }) => {

    const [activeStep, setActiveStep] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonDisabledForm, setButtonDisabledForm] = useState(false);
    const [tomaAsistencia, setTomaAsistencia] = useState(false);
    const [status, setStatus] = useState('');
    const [messageAsistencia, setMessageAsistencia] = useState('');
    const [preguntas, setPreguntas] = useState([]);
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
    const [estudiantesConIdUnico, setEstudiantesConIdUnico] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);  //Cuento registros seleccionados

    // Mapear la lista de estudiantes para asegurarte de que cada uno tenga un id único
    useEffect(() => {
        setEstudiantesConIdUnico(
            listaEstudiantes.map((estudiante, index) => ({
                ...estudiante,
                id: `${estudiante.num_identificacion}-${index}`, // Genera un id único combinando num_identificacion y el índice
                asistencia: 0, // Asistencia inicializado en 0
            }))
        );
    }, [listaEstudiantes]);

    

    const handleNext = () => {
        if (activeStep === preguntas.length - 1) {
            finalizarFormulario();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const finalizarFormulario = () => {
        setButtonDisabledForm(true);
        
        // Agregar codMateria y numIdentificacionDocente a cada pregunta
        const codMateria = listaEstudiantes[0].cod_materia;
        const preguntasConDatos = preguntas.map(pregunta => ({
            ...pregunta,
            codMateria: codMateria,
            numIdentificacionDocente: numIdentificacionDocente,
        }));
    
        formulariosApi.post('guardarRespuestasFormularioDocentes', {
            preguntas: preguntasConDatos,
        })
        .then(resp => {
            Swal.fire({
                title: '¡Gracias por compartirnos tu opinión!',
                text: resp.data.message,
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#047835',
                confirmButtonText: 'Aceptar'
            });
            // Establecer encuesta como completada
            setIsSurveyCompleted(true);
        })
        .catch(error => {
            setButtonDisabledForm(false);
            console.log(error)
        });
    };
    
    

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const respuestaSeleccionada = ({ preguntaId, respuesta }) => {
        const data = [...preguntas];
        const pregunta = data.find(resp => resp.id === preguntaId);
        if (pregunta) {
            pregunta.value = respuesta;
            setPreguntas(data);
        }
        // Verifica si todas las preguntas han sido respondidas
        const allAnswered = data.every(pregunta => pregunta.value !== undefined && pregunta.value !== '');
        setButtonDisabled(!tomaAsistencia || !allAnswered);
    };

    const handleTextboxChange = (preguntaId, value) => {
        const data = [...preguntas];
        const pregunta = data.find(preg => preg.id === preguntaId);
        if (pregunta) {
            pregunta.textboxValue = value;
            setPreguntas(data);
        }
        // Verifica si todas las preguntas han sido respondidas
        const allAnswered = data.every(pregunta => pregunta.value !== undefined && pregunta.value !== '');
        setButtonDisabled(!tomaAsistencia || !allAnswered);
    };

    const asistenciaRealizada = listaEstudiantes[0]?.fecha_asistencia || null;


    // Guardar asistencia
    const onSubmitAsistencia = async () => {
        if (!tomaAsistencia || !isSurveyCompleted) {
            Swal.fire({
                title: 'Por favor, complete la encuesta y seleccione registros.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#047835',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        setStatus('loading');

        await docentesApi.post('guardarAsistenciaEstudiantes', { estudiantes: estudiantesConIdUnico, horario: JSON.parse(horario) })
        .then(resp => {
            setStatus('saved');
            setMessageAsistencia(resp.data.message);
        })
        .catch(error => {
            setStatus('error');
            setMessageAsistencia('');
            Swal.fire({
                title: 'Error al guardar asistencia',
                text: 'Error al guardar registro de asistencia: ' + error.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#047835',
                confirmButtonText: 'Aceptar'
            });
        });

        // Imprimir en consola el objeto completo de respuestas
        console.log('Respuestas:', preguntas.map(pregunta => ({
            id: pregunta.id,
            valor: pregunta.value,
            textboxValue: pregunta.textboxValue
        })));
    
    };
    

    const preguntasEncuestaDocentes = async () => {
        setStatus('loading');
        await formulariosApi.get('/preguntasFormularioDocentes')
            .then(resp => {
                setPreguntas(resp.data);
                setStatus('loaded');
            })
            .catch(error => {
                Swal.fire({
                    title: '¡Error al cargar las preguntas de la encuesta!',
                    text: error.message,
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#047835',
                    confirmButtonText: 'Aceptar'
                });
                setPreguntas([]);
                setStatus('loaded');
            });
    };

    const textosEmojis = (pregunta) => {
        if (pregunta.tipo !== 'likert') return;
        return Object.assign({}, [pregunta['rango_1'], pregunta['rango_2'], pregunta['rango_3'], pregunta['rango_4'], pregunta['rango_5']]);
    };

    useEffect(() => {
        preguntasEncuestaDocentes();
    }, []);


    const onState = (state) => {
        const { selection } = state;
    
        if (selection.length) {
            setTomaAsistencia(selection.length > 0);
            setSelectedCount(selection.length);
    
            // Solo actualizar si los valores cambian
            const updatedEstudiantes = estudiantesConIdUnico.map(estudiante => {
                const nuevoValor = selection.includes(estudiante.id) ? 1 : 0;
                if (estudiante.asistencia !== nuevoValor) {
                    return {
                        ...estudiante,
                        asistencia: nuevoValor,
                    };
                }
                return estudiante;
            });
    
            // Verificar si hay algún cambio en el array antes de actualizar el estado
            const hasChanged = updatedEstudiantes.some((estudiante, index) => 
                estudiante.asistencia !== estudiantesConIdUnico[index].asistencia
            );
    
            if (hasChanged) {
                setEstudiantesConIdUnico(updatedEstudiantes);
            }
        }
    };

    const columns = [
        { field: 'nom_largo', headerName: 'Nombres', width: 320, sortable: false },
        { field: 'num_identificacion', headerName: 'Identificación', width: 200, sortable: false },
        { field: 'cod_materia', headerName: 'Código materia', width: 200, sortable: false },
    ];

    if (status === 'loading') {
        return <LoadingData />;
    }

    if (status === 'saved') {
        return (
            <Grid textAlign={'center'}>
                {/* <h1>Asistencia guardada correctamente: {messageAsistencia}</h1> */}
                <h1>Asistencia guardada correctamente: Estudiantes actualizados {selectedCount}</h1>
            </Grid>
        );
    }

    if (asistenciaRealizada) {
        return (
            <Grid textAlign={'center'}>
                <h1>Asistencia realizada el día: {asistenciaRealizada} </h1>
            </Grid>
        );
    }

    return (
        <div style={{ height: 500, width: '100%', marginBottom: '5%' }}>
            <DataGrid
                rows={estudiantesConIdUnico}
                columns={columns}
                pageSize={estudiantesConIdUnico.length}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onStateChange={(state) => onState(state)}
            />
            <Stepper activeStep={activeStep} orientation="vertical">
                {preguntas.map((pregunta, index) => (
                    <Step key={pregunta.label} sx={{ mb: 2 }}>
                        <StepLabel
                            optional={
                                index === (preguntas.length - 1) ? (
                                    <Typography variant="caption">Última pregunta</Typography>
                                ) : null
                            }
                        >
                            <Typography variant="h5" component="h1">
                                {pregunta.label}
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Typography>{pregunta.description}</Typography>

                            {/* Renderizar el tipo de respuesta requerido */}
                            <Box sx={{ mt: 3 }}>
                                <TipoRespuestaPreguntaDocente
                                    pregunta={pregunta}
                                    respuestaSeleccionada={respuestaSeleccionada}
                                    textosEmojis={textosEmojis(pregunta)}
                                    handleTextboxChange={handleTextboxChange}
                                />
                            </Box>

                            <Box sx={{ mb: 2, mt: 3 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={!pregunta.value || buttonDisabledForm}
                                    >
                                        {index === preguntas.length - 1 ? 'Finalizar' : 'Continuar'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, ml: -1 }}
                                    >
                                        Atrás
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

            {esAdmin === false && 
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    sx={{ mt: 3, mb: 5 }}
                >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                    <Button 
                        variant="contained" 
                        disabled={buttonDisabled || !isSurveyCompleted}
                        onClick={onSubmitAsistencia}
                    >
                        Guardar toma de asistencia
                    </Button>

                    </Grid>
                </Grid>
            }
        </div>
    );
};
