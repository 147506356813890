import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useForm } from '../../hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  bgcolor: 'background.paper',
  border: '3px solid #047835',
  borderRadius: '5px',
  boxShadow: 28,
  p: 4,
};

export const BusquedaHorariosDocente = ({ consultarHorariosDocente }) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);  

    const inputForms = {
        emailDocente: '',
        identificacionDocente: '',
        diaHorario: '',
    }

    const formValidations = {
        emailDocente: [ (value) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value), 'Debe ingresar un correo valido' ],
    }

    const { handleInputChange, emailDocente, identificacionDocente, diaHorario, isFormValid, emailDocenteValid } = useForm(inputForms, formValidations);

    const handleBuscarHorariosDocente = () => {
        consultarHorariosDocente({ 
            email: emailDocente, 
            identificacion: identificacionDocente, 
            num_dia: diaHorario,
            esAdmin: true
        });
    }

  return (
    <div>
        <Button 
            onClick={handleOpen}
            startIcon={<PersonSearchIcon />}
            >
                Consultar docente
        </Button>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Ingresar email y opcional el número de identificación.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Puedes hacer la consulta del horario del docente con email o los dos parametros.
            </Typography>


            <Grid item xs={ 12 } sx={{ mt: 2 }}>
                <FormControl 
                    fullWidth
                    error={ !!emailDocenteValid }                
                >

                <TextField
                    label="Email docente" 
                    type="email" 
                    placeholder="Email docente"
                    fullWidth
                    name="emailDocente"
                    value={ emailDocente }
                    onChange={ handleInputChange }                    
                    />

                <FormHelperText >{ emailDocenteValid }</FormHelperText>
                </FormControl>
            </Grid> 

            <Grid item xs={ 12 } sx={{ mt: 2 }}>
                <TextField
                    label="Número identificación docente" 
                    type="text" 
                    placeholder="Número identificación docente"
                    fullWidth
                    name="identificacionDocente"
                    value={ identificacionDocente }
                    onChange={ handleInputChange }                    
                    />
            </Grid>

            <Grid item xs={ 12 } sx={{ mt: 3 }}>
                <FormControl 
                    fullWidth
                    >
                    <InputLabel id="diaHorario">Día (opcional)</InputLabel>
                    <Select
                        labelId="diaHorario"
                        id="programa-simple-select"
                        name="diaHorario"
                        value={ diaHorario }
                        label="diaHorario"
                        onChange={ handleInputChange }
                    >
                        <MenuItem value="">Seleccionar día</MenuItem>
                        <MenuItem value="2">Lunes</MenuItem>
                        <MenuItem value="3">Martes</MenuItem>
                        <MenuItem value="4">Miercoles</MenuItem>
                        <MenuItem value="5">Jueves</MenuItem>
                        <MenuItem value="6">Viernes</MenuItem>
                        <MenuItem value="7">Sabado</MenuItem>
                    </Select>
                </FormControl>                
            </Grid>                   

            <Grid item xs={ 12 } sx={{ mt: 3 }}>
                <Button
                    type="button" 
                    variant="contained" 
                    fullWidth 
                    size="large"
                    startIcon={<ManageSearchIcon />}
                    onClick={ () => handleBuscarHorariosDocente() }
                    disabled={ !isFormValid }
                >
                    Consultar horarios del docente  
                </Button>
            </Grid>                   


        </Box>
        </Modal>
    </div>
  )
}
